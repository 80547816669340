import "./styles.scss"

import React, { useRef } from "react"
import {
  useMotionTemplate,
  useScroll,
  motion,
  useTransform,
} from "framer-motion"

import { DZIELNICA_HERO_DATA } from "../../data"

import { useLocale } from "context/locale"

const Mobile = () => {
  const { t } = useLocale()

  const ref = useRef(null)

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start", "end"],
  })

  const timing = [0, 1]

  const heading_left_x = useTransform(progress, timing, [0, -100])
  const heading_left_transform = useMotionTemplate`translate(${heading_left_x}%, 0)`
  const heading_right_x = useTransform(progress, timing, [0, 100])
  const heading_right_transform = useMotionTemplate`translate(${heading_right_x}%, 0)`

  const image_size_transform = useTransform(progress, timing, [50, 100])
  const image_size = useMotionTemplate`${image_size_transform}%`
  const image_scale = useTransform(progress, timing, [1.5, 1])
  const image_transform = useMotionTemplate`scale(${image_scale})`

  return (
    <div className="dzielnica-hero-mobile" ref={ref}>
      <div className="dzielnica-hero-mobile__wrapper">
        <div className="dzielnica-hero-mobile__wrapper-inner">
          <div className="dzielnica-hero-mobile__investment">
            <div className="container-fluid">
              <div className="dzielnica-hero-mobile__investment-logo">
                <img
                  src={DZIELNICA_HERO_DATA.investment.logo.image}
                  alt={DZIELNICA_HERO_DATA.investment.logo.alt}
                />
              </div>
              <h4>{t(DZIELNICA_HERO_DATA.investment.stage)}</h4>
            </div>
          </div>
          <div className="dzielnica-hero-mobile__image">
            <motion.div
              className="dzielnica-hero-mobile__heading dzielnica-hero-mobile__heading--left"
              style={{
                transform: heading_left_transform,
              }}
            >
              <div>{t(DZIELNICA_HERO_DATA.heading.left)}</div>
            </motion.div>
            <motion.div
              className="dzielnica-hero-mobile__heading dzielnica-hero-mobile__heading--right"
              style={{
                transform: heading_right_transform,
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t(DZIELNICA_HERO_DATA.heading.right),
                }}
              />
            </motion.div>
            <div className="dzielnica-hero-mobile__image-outer">
              <motion.div
                className="dzielnica-hero-mobile__image-inner"
                style={{ width: image_size, height: image_size }}
              >
                <motion.img
                  src={DZIELNICA_HERO_DATA.background.mobile}
                  alt=""
                  style={{ transform: image_transform }}
                />
              </motion.div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="dzielnica-hero-mobile__caption">
              <p>{t(DZIELNICA_HERO_DATA.caption.name)}</p>
              <p>{t(DZIELNICA_HERO_DATA.caption.location)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mobile
