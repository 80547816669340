import "./styles.scss"

import React from "react"

import AnimatedMask from "components/AnimatedMask"

const Residence = () => {
  return (
    <>
      <span className="anchor" id="new" />
      <section className="dzielnica-residence">
        <div className="container-dzielnica">
          <div className="row">
            <div className="col-lg-7">
              <div className="dzielnica-label">Dowiedz się</div>
              <h2>
                Dzielnica z wyjątkową
                <br /> historią w tle
              </h2>
              <div className="dzielnica-residence__description">
                <p>
                  Osiedle na Stoku to miejsce, które wyraźnie wpisało się w
                  kulturalny i społeczny krajobraz Kielc. To właśnie tutaj
                  powstaje inwestycja{" "}
                  <strong>
                    Dzielnica Trust – naturalna kontynuacja projektu Rezydencja
                    Sikorskiego
                  </strong>
                  . Kompleks będzie składał się z pięciu eleganckich budynków w
                  stylu rezydencyjnym, które wspólnie stworzą spójną i
                  harmonijną koncepcję osiedla mieszkaniowego.
                </p>
              </div>

              <div className="row align-items-end">
                <div className="col-xl-6 col-md-7">
                  <div className="dzielnica-residence__image dzielnica-residence__image--1">
                    <div>
                      <img
                        src={
                          require("assets/images/dzielnica-trust/residence-image-1.jpg")
                            .default
                        }
                        alt=""
                      />
                      <AnimatedMask variant="dzielnica" />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <p>
                    Miejsce to jest jedną z najbardziej pożądanych lokalizacji
                    do zamieszkania. Wszystko za sprawą rozwiniętej
                    infrastruktury miejskiej oraz bliskości rozległych terenów
                    zielonych. Osiedle łączy w sobie nowoczesność z historią,
                    oferując przestrzeń do życia zarówno dla rodzin, jak i osób
                    szukających miejskiego spokoju.{" "}
                    <strong>
                      Charakterystyczną cechą budynków są duże, narożne
                      przeszklenia oraz przestronne balkony, które zapewniają
                      mieszkańcom dostęp do naturalnego światła i pięknych
                      widoków.
                    </strong>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 offset-xl-1 col-lg-5">
              <div className="dzielnica-residence__image dzielnica-residence__image--2">
                <div>
                  <img
                    src={
                      require("assets/images/dzielnica-trust/residence-image-2.jpg")
                        .default
                    }
                    alt=""
                  />
                  <AnimatedMask variant="dzielnica" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-9">
              <div className="dzielnica-residence__image dzielnica-residence__image--3">
                <div>
                  <img
                    src={
                      require("assets/images/dzielnica-trust/residence-image-3.jpg")
                        .default
                    }
                    alt=""
                  />
                  <AnimatedMask variant="dzielnica" />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="dzielnica-residence__description">
                  <p>
                    Części wspólne są spójne z architektoniczną wizją budynku,
                    która łączy nowoczesność, subtelną elegancję i wyrafinowany
                    styl. Każda klatka schodowa zostanie wyposażona w specjalny
                    system zapachowy, który zapewni przyjemną atmosferę wewnątrz
                    budynków.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-end">
            <div className="col-lg-5 offset-lg-1">
              <div className="dzielnica-residence__image dzielnica-residence__image--4">
                <div>
                  <img
                    src={
                      require("assets/images/dzielnica-trust/residence-image-4.jpg")
                        .default
                    }
                    alt=""
                  />
                  <AnimatedMask variant="dzielnica" />
                </div>
                <p>
                  <strong>
                    Nowoczesność osiedla podkreśla również Blue Bolt
                  </strong>{" "}
                  – aplikacja, która umożliwia zdalne otwieranie hali garażowej,
                  przywoływanie windy czy otwieranie klatki schodowej za pomocą
                  smartfona.
                </p>
              </div>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <div className="dzielnica-residence__image dzielnica-residence__image--5">
                <div>
                  <img
                    src={
                      require("assets/images/dzielnica-trust/residence-image-5.jpg")
                        .default
                    }
                    alt=""
                  />
                  <AnimatedMask variant="dzielnica" />
                </div>
                <p>
                  <strong>
                    Osiedle otaczać będzie różnorodna zieleń, która nie tylko
                    upiększy przestrzeń, ale również wpłynie na komfort życia
                    mieszkańców, tworząc oazę spokoju w miejskim krajobrazie.
                  </strong>{" "}
                  Dodatkowo do dyspozycji mieszkańców będzie ponad 100 stojaków
                  rowerowych, co idealnie wpisuje się w charakter osiedla,
                  połączonego z licznymi ścieżkami rowerowymi w okolicy.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="dzielnica-residence__image dzielnica-residence__image-row">
                <div>
                  <img
                    src={
                      require("assets/images/dzielnica-trust/residence-grid-image-1.jpg")
                        .default
                    }
                    alt=""
                  />
                  <AnimatedMask variant="dzielnica" />
                </div>
                <p>
                  <strong>Myjnia dla rowerów</strong> – szybko i wygodnie
                  zadbasz o czystość swojego roweru po każdej wyprawie.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dzielnica-residence__image dzielnica-residence__image-row">
                <div>
                  <img
                    src={
                      require("assets/images/dzielnica-trust/residence-grid-image-2.jpg")
                        .default
                    }
                    alt=""
                  />
                  <AnimatedMask variant="dzielnica" />
                </div>
                <p>
                  <strong>Zadaszona rowerownia</strong> – dzięki niej Twój rower
                  będzie zawsze chroniony przed deszczem, śniegiem i słońcem.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dzielnica-residence__image dzielnica-residence__image-row">
                <div>
                  <img
                    src={
                      require("assets/images/dzielnica-trust/residence-grid-image-3.jpg")
                        .default
                    }
                    alt=""
                  />
                  <AnimatedMask variant="dzielnica" />
                </div>
                <p>
                  <strong>Prywatny paczkomat</strong> – odbieraj i nadawaj
                  przesyłki wtedy, kiedy Ci wygodnie, bez kolejek.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Residence
