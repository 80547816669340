export const DZIELNICA_HERO_DATA = {
  investment: {
    logo: {
      image: require("assets/images/dzielnica-trust/logo-dt.svg").default,
      alt: "Dzielnica Trust",
    },
    stage: "",
  },
  heading: {
    left: "Poczuj rytm",
    right: "nowy<br/> Na Stoku",
  },
  background: {
    desktop: require("assets/images/dzielnica-trust/hero-image.jpg").default,
    mobile: require("assets/images/dzielnica-trust/hero-image-mobile.jpg")
      .default,
  },
  caption: {
    location: "ul. Gen. Władysława Sikorskiego",
    name: "Kielce",
  },
}
