import "./styles.scss"

import React from "react"
import scrollToElement from "scroll-to-element"

import { useLocale } from "context/locale"

const CallToAction = () => {
  const { t } = useLocale()

  return (
    <section className="dzielnica-cta dzielnica-cta--hidden">
      <button onClick={() => scrollToElement("#contact")}>
        {t("Zapytaj o mieszkanie")}
      </button>
    </section>
  )
}

export default CallToAction
