import "./styles.scss"

import React, { useState, useEffect } from "react"
import scrollToElement from "scroll-to-element"
import Scrollspy from "react-scrollspy"

import { useLocale } from "context/locale"

const Nav = () => {
  const { t } = useLocale()
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsScrolled(window.scrollY > 1000)
    })
  })

  return (
    <section className={`dzielnica-nav${isScrolled ? " visible" : ""}`}>
      <div className="container-fluid">
        <div className="dzielnica-nav__wrapper">
          <strong>Dzielnica Trust</strong>
          <nav>
            <Scrollspy
              items={[
                "apartments",
                "space",
                "new",
                "gallery",
                "location",
                "contact",
              ]}
              currentClassName="is-active"
              offset={200}
            >
              <button onClick={() => scrollToElement("#apartments")}>
                {t("Mieszkania")}
              </button>
              <button onClick={() => scrollToElement("#space")}>
                {t("Udogodnienia ")}
              </button>
              <button onClick={() => scrollToElement("#new")}>
                {t("O inwestycji")}
              </button>
              <button onClick={() => scrollToElement("#gallery")}>
                {t("Galeria")}
              </button>
              <button onClick={() => scrollToElement("#location")}>
                {t("Topowa lokalizacja")}
              </button>
              <button onClick={() => scrollToElement("#contact")}>
                {t("Kontakt")}
              </button>
            </Scrollspy>
          </nav>
        </div>
      </div>
    </section>
  )
}

export default Nav
