const DZIELNICA_GALLERY_DATA = [
  {
    category: "Na zewnątrz",
    catalog: "zewnatrz",
    images_small: [
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_16_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_17_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_18_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_19_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_20_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_21_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_22_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_23_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_24_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_25_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_26_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_27_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_28_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_29_miniatura.jpg`)
        .default,
    ],
    images_url: [
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_16_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_17_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_18_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_19_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_20_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_21_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_22_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_23_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_24_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_25_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_26_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_27_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_28_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/zewnatrz/galeria_29_duze.jpg`)
        .default,
    ],
  },

  {
    category: "Części wspólne",
    catalog: "czesci_wspolne",
    images_small: [
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_1_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_2_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_3_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_4_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_5_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_6_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_7_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_8_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_9_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_10_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_11_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_12_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_13_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_14_miniatura.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_15_miniatura.jpg`)
        .default,
    ],
    images_url: [
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_1_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_2_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_3_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_4_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_5_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_6_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_7_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_8_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_9_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_10_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_11_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_12_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_13_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_14_duze.jpg`)
        .default,
      require(`assets/images/dzielnica-trust/czesci_wspolne/galeria_15_duze.jpg`)
        .default,
    ],
  },
]

export { DZIELNICA_GALLERY_DATA }
