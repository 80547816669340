import React, { useState, useEffect } from "react"
import axios from "axios"
import { navigate } from "gatsby"

import { useVoxLegals } from "hooks"

import Input from "components/Input"
import FormLegals from "components/FormLegals"

import { useLocale } from "context/locale"

const Form = () => {
  const { t } = useLocale()

  const { selectLegal, acceptedLegals } = useVoxLegals()

  const formID = "22661"

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  })
  const [legalFirst, setLegalFirst] = useState(false)
  const [legalSecond, setLegalSecond] = useState(false)
  const [legalThird, setLegalThird] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const isBrowser = typeof window !== "undefined"
  const location = isBrowser ? window.location.href : null

  useEffect(() => {
    selectLegal(["marketing", "mail", "phone", "sms"], legalFirst && legalThird)
    selectLegal("statement", legalSecond)
  }, [selectLegal, legalFirst, legalSecond, legalThird])

  const formSubmit = e => {
    e.preventDefault()

    setIsSending(true)

    let form = new FormData()

    Object.entries(formData).forEach(([key, value]) => {
      form.set(key, value)
    })

    if (acceptedLegals) {
      Object.entries(acceptedLegals).forEach(([key, value]) => {
        form.set(key, value)
      })
    }

    axios({
      method: "post",
      url: `https://trust-investment.zenx.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        isBrowser &&
          window.Monitor.TrackForm("trustForm", [
            { "yl-investment": "Dzielnica Trust" },
            { "yl-name": `${formData.firstName} ${formData.lastName}` },
            { "yl-email": formData.email },
            { "yl-phone": formData.phone },
            { "yl-site_url": location },
            { "yl-legal-1": legalFirst },
            { "yl-legal-2": legalSecond },
            { "yl-legal-3": legalThird },
          ])

        navigate(t("/kontakt-potwierdzenie/"))
      })
      .catch(_ => {
        setIsSending(false)
      })
  }

  return (
    <form onSubmit={formSubmit} id="trustForm">
      <Input
        placeholder={`${t("Imię")}*`}
        type="text"
        id="first_name"
        name="first_name"
        value={formData.firstName}
        onChange={e =>
          setFormData(prevData => ({
            ...prevData,
            firstName: e.target.value,
          }))
        }
        required
      />
      <Input
        placeholder={`${t("Nazwisko")}*`}
        type="text"
        id="last_name"
        name="last_name"
        value={formData.lastName}
        onChange={e =>
          setFormData(prevData => ({
            ...prevData,
            lastName: e.target.value,
          }))
        }
        required
      />
      <Input
        placeholder={`${t("Adres e-mail")}*`}
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={e =>
          setFormData(prevData => ({
            ...prevData,
            email: e.target.value,
          }))
        }
        required
      />
      <Input
        placeholder={`${t("Numer telefonu")}*`}
        type="phone"
        id="phone"
        name="phone"
        value={formData.phone}
        onChange={e =>
          setFormData(prevData => ({
            ...prevData,
            phone: e.target.value,
          }))
        }
        required
      />
      <div className="contact-form__action">
        <FormLegals
          legalFirst={legalFirst}
          setLegalFirst={setLegalFirst}
          legalSecond={legalSecond}
          setLegalSecond={setLegalSecond}
          legalThird={legalThird}
          setLegalThird={setLegalThird}
        />
      </div>
      <div className="dzielnica-contact__cta">
        <button
          type="submit"
          disabled={!legalFirst || !legalSecond || !legalThird || isSending}
        >
          {isSending ? `${t("Wysyłanie")}...` : t("Wyślij wiadomość")}
        </button>
      </div>
    </form>
  )
}

export default Form
