import "./styles.scss"

import React, { useRef } from "react"
import {
  motion,
  useMotionTemplate,
  useTransform,
  useScroll,
} from "framer-motion"
import scrollToElement from "scroll-to-element"

import { useLocale } from "context/locale"

const Baner = ({ spacerRef }) => {
  const { t } = useLocale()
  const ref = useRef(null)

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start end", "end"],
  })
  const timing = [0, 1]

  const shapes_left_x = useTransform(progress, timing, [-12.5, 0])
  const shapes_left_transform = useMotionTemplate`translate(${shapes_left_x}%, 0)`
  const shapes_right_x = useTransform(progress, timing, [12.5, 0])
  const shapes_right_transform = useMotionTemplate`translate(${shapes_right_x}%, 0)`

  return (
    <section className="dzielnica-baner" ref={ref}>
      <div className="dzielnica-baner__wrapper">
        <button
          className="dzielnica-baner__cta"
          onClick={() => scrollToElement("#contact")}
        >
          {t("Porozmawiajmy")}
        </button>

        <motion.div
          className="dzielnica-baner__shape dzielnica-baner__shape--left"
          style={{ transform: shapes_left_transform }}
        />
        <motion.div
          className="dzielnica-baner__shape dzielnica-baner__shape--right"
          style={{ transform: shapes_right_transform }}
        />
      </div>

      <div className="dzielnica-baner__spacer">
        <div className="dzielnica-baner__spacer-inner" ref={spacerRef} />
      </div>
    </section>
  )
}

export default Baner
