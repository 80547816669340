import "./styles.scss"

import React, { useRef } from "react"
import {
  useScroll,
  useTransform,
  motion,
  useMotionTemplate,
} from "framer-motion"

import Baner from "../Baner"
import Location from "../Location"

const Transition = () => {
  const ref = useRef(null)

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start", "end start"],
  })

  const image_y = useTransform(progress, [0, 1], [12.5, -12.5])
  const image_transform = useMotionTemplate`translate(0, ${image_y}%)`

  return (
    <div className="dzielnica-transition">
      <Baner spacerRef={ref} />
      <div className="dzielnica-transition__background">
        <motion.div
          className="dzielnica-transition__background-inner"
          style={{ transform: image_transform }}
        >
          <img
            src={
              require("assets/images/dzielnica-trust/transition-image.jpg")
                .default
            }
            alt=""
          />
        </motion.div>
      </div>
      <Location />
    </div>
  )
}

export default Transition
