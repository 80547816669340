import "./styles.scss"

import React, { useState } from "react"
import { motion } from "framer-motion"

import { Form } from "../components"
import AnimatedMask from "components/AnimatedMask"

import { useLocale } from "context/locale"

const variants = {
  hidden: {
    y: 32,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const getTransition = delay => ({
  type: "tween",
  duration: 0.6,
  delay,
})

const Contact = () => {
  const { t } = useLocale()
  const [animate, setAnimate] = useState(false)

  return (
    <>
      <span id="contact" className="anchor anchor--small" />
      <section className="dzielnica-contact">
        <div className="container-dzielnica">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div
                className="dzielnica-contact__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/dzielnica-trust/contact-image.jpg")
                      .default
                  }')`,
                }}
              >
                <AnimatedMask
                  variant="dzielnica-green"
                  callback={() => setAnimate(true)}
                />
              </div>
            </div>

            <div className="col-md-6 offset-lg-1">
              <div className="dzielnica-contact__inner">
                <motion.div
                  variants={variants}
                  initial="hidden"
                  animate={animate ? "visible" : "hidden"}
                  transition={getTransition(0.4)}
                >
                  <div className="dzielnica-label">{t("Kontakt")}</div>
                  <h3>
                    Zapytaj o Apartament
                    <br /> w Dzielnicy Trust
                  </h3>
                </motion.div>

                <motion.div
                  className="dzielnica-contact__form"
                  variants={variants}
                  initial="hidden"
                  animate={animate ? "visible" : "hidden"}
                  transition={getTransition(0.6)}
                >
                  <Form />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
