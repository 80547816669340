import "./styles.scss"

import React, { useRef } from "react"
import { useScroll } from "framer-motion"

import StringIllumination from "components/StringIllumination"

import { useLocale } from "context/locale"

const About = () => {
  const { t } = useLocale()
  const quoteRef = useRef(null)

  const { scrollYProgress: quoteOneProgress } = useScroll({
    target: quoteRef,
    offset: ["start end", "center end"],
  })

  return (
    <>
      <span id="about" className="anchor anchor--small" />
      <section className="dzielnica-about">
        <div className="dzielnica-about__quote" ref={quoteRef}>
          <div className="dzielnica-about__quote-inner">
            <div className="container-dzielnica">
              <div className="dzielnica-about__quote-wrapper">
                <div className="dzielnica-about__quote-content">
                  <p>
                    <StringIllumination
                      string={t(
                        "„Dzielnica Trust to przemyślana koncepcja osiedla, która łączy nowoczesność z komfortem codziennego życia – wszystko, czego potrzebujesz, jest w zasięgu 15 minut. Zielone tereny, otaczające osiedle podkreślają wyjątkowy charakter miejsca, oferując mieszkańcom przestrzeń do relaksu i życia w harmonii z naturą.”"
                      )}
                      progress={quoteOneProgress}
                      colors={{
                        start: "#000000",
                        end: "#F7E9D5",
                      }}
                    />
                  </p>
                </div>
                <p className="dzielnica-about__quote-author">
                  {t("Zespół Trust Investment")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About
