import "swiper/css"
import "./styles.scss"

import React, { useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Autoplay, Keyboard } from "swiper/modules"

import { useCursor } from "context/cursor"
import { useLocale } from "context/locale"

const swiperProps = {
  className: "dzielnica-slider__carousel-swiper",
  modules: [Navigation, Autoplay, Keyboard],
  slidesPerView: "auto",
  speed: 1000,
  loop: true,
  allowTouchMove: false,
  spaceBetween: 15,
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  autoplay: {
    delay: 5000,
  },
  navigation: {
    prevEl: ".dzielnica-slider__navigation-arrow--prev",
    nextEl: ".dzielnica-slider__navigation-arrow--next",
  },
  breakpoints: {
    651: {
      spaceBetween: 20,
    },
  },
}

const data = [
  {
    title: "Galeria Echo",
    image:
      require("assets/images/dzielnica-trust/lokalizacja_slider_1_duze.jpg")
        .default,
  },
  {
    title: "Sklepy spożywcze",
    image:
      require("assets/images/dzielnica-trust/lokalizacja_slider_2_duze.jpg")
        .default,
  },
  {
    title: "Bawialnia dla dzieci ",
    image:
      require("assets/images/dzielnica-trust/lokalizacja_slider_3_duze.jpg")
        .default,
  },
  {
    title: "Orlik Boisko Jeziorańskiego ",
    image:
      require("assets/images/dzielnica-trust/lokalizacja_slider_4_duze.jpg")
        .default,
  },
  {
    title: "Tereny zielone",
    image:
      require("assets/images/dzielnica-trust/lokalizacja_slider_5_duze.jpg")
        .default,
  },
]

const SliderLocation = () => {
  const { t } = useLocale()
  const swiperRef = useRef(null)
  const arrowPrevRef = useRef(null)

  const { setCursor } = useCursor()

  return (
    <section className="dzielnica-slider">
      <div className="container-dzielnica">
        <p>Wszystko po sąsiedzku</p>
      </div>
      <div className="dzielnica-slider__carousel">
        <Swiper {...swiperProps} ref={swiperRef}>
          {[...data, ...data].map((item, index) => (
            <SwiperSlide key={index}>
              <div className="dzielnica-slider__item">
                <div style={{ backgroundImage: `url('${item?.image}')` }}></div>
                <span>{t(item?.title)}</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="dzielnica-slider__navigation">
          <div className="container-dzielnica">
            <div className="dzielnica-slider__navigation-autoplay">
              <div className="dzielnica-slider__navigation-autoplay-inner">
                <button
                  type="button"
                  onMouseEnter={() => {
                    swiperRef.current.swiper.autoplay.stop()
                    setCursor([true, "arrow-left"])
                  }}
                  onMouseLeave={() => {
                    swiperRef.current.swiper.autoplay.start()
                    setCursor([null, "arrow-left"])
                  }}
                  onClick={() => {
                    arrowPrevRef.current.click()
                  }}
                >
                  Stop
                </button>
              </div>
            </div>
          </div>
          <button
            ref={arrowPrevRef}
            className="dzielnica-slider__navigation-arrow dzielnica-slider__navigation-arrow--prev"
            type="button"
            onMouseEnter={() => setCursor([true, "arrow-left"])}
            onMouseLeave={() => setCursor([null, "arrow-left"])}
          >
            Poprzedni
          </button>
          <button
            className="dzielnica-slider__navigation-arrow dzielnica-slider__navigation-arrow--next"
            type="button"
            onMouseEnter={() => setCursor([true, "arrow-right"])}
            onMouseLeave={() => setCursor([null, "arrow-right"])}
          >
            Następny
          </button>
        </div>
        <div className="dzielnica-slider__spacer">
          <div />
        </div>
      </div>
    </section>
  )
}

export default SliderLocation
