import React from "react"
import { withPrefix } from "gatsby"

import CursorProvider from "context/cursor"

import Layout from "components/Layout"
import {
  Hero,
  Discover,
  Entertainment,
  Residence,
  Transition,
  SliderLocation,
  Interior,
  GalleryBottom,
  About,
  Contact,
  CallToAction,
  Nav,
} from "page_components/dzielnica-trust"

import { Apartments } from "page_components/investment"

const Investment = ({ location }) => {
  const investment = "Dzielnica Trust"
  const investmentRaw = "dzielnica_trust"

  return (
    <CursorProvider variant="hugo">
      <Layout
        location={location}
        rmGlobalPopup
        mainClass="dzielnica-landing"
        seo={{
          title: investment,
          image: withPrefix("/dt-og-image.jpg"),
        }}
      >
        <Nav />
        <Hero />
        <Discover />
        <Apartments investment={investment} investmentRaw={investmentRaw} />
        <Entertainment />
        <Residence />
        <Transition />
        <SliderLocation />
        <Interior />
        <GalleryBottom />
        <About />
        <Contact />
        <CallToAction />
      </Layout>
    </CursorProvider>
  )
}

export default Investment
