import "./styles.scss"

import React, { useRef } from "react"
import {
  useMotionTemplate,
  useScroll,
  motion,
  useTransform,
} from "framer-motion"

import { DZIELNICA_HERO_DATA } from "../../data"

import { useLocale } from "context/locale"

const Tall = () => {
  const { t, lang } = useLocale()
  const ref = useRef(null)

  const { scrollYProgress: progress } = useScroll({
    target: ref,
    offset: ["start", "end"],
  })

  const timing = [0, 1]
  const timing_prehalf = [0, 0.5]

  const investment_y = useTransform(progress, timing, [0, -100])
  const investment_y_transform = useMotionTemplate`translate(0, ${investment_y}%)`

  const heading_left_x = useTransform(progress, timing, [0, -650])
  const heading_left_transform = useMotionTemplate`translate(${heading_left_x}%, 0)`
  const heading_right_x = useTransform(progress, timing, [0, 650])
  const heading_right_transform = useMotionTemplate`translate(${heading_right_x}%, 0)`

  const image_cp_top = useTransform(progress, timing, [12.91666667, 0])
  const image_cp_right = useTransform(progress, timing, [64.16666667, 100])
  const image_cp_bottom = useTransform(progress, timing, [87.08333333, 100])
  const image_cp_left = useTransform(progress, timing, [35.83333333, 0])
  const image_cp_transform = useMotionTemplate`polygon(${image_cp_right}% ${image_cp_top}%, ${image_cp_right}% ${image_cp_bottom}%, ${image_cp_left}% ${image_cp_bottom}%,  ${image_cp_left}% ${image_cp_top}%)`

  const image_scale = useTransform(progress, timing, [0.88, 1])
  const image_scale_transform = useMotionTemplate`scale(${image_scale})`

  const caption_opacity = useTransform(progress, timing_prehalf, [1, 0])

  return (
    <div className="dzielnica-hero-tall" ref={ref}>
      <motion.div
        className="dzielnica-hero-tall__wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: "tween", ease: "easeInOut", duration: 0.6 }}
      >
        <div className="dzielnica-hero-tall__wrapper-inner">
          <div className="dzielnica-hero-tall__container">
            <motion.div
              className="dzielnica-hero-tall__investment"
              style={{
                transform: investment_y_transform,
              }}
            >
              <div className="container-fluid">
                <div className="hugo-hero-tall__investment-logo">
                  <img
                    src={DZIELNICA_HERO_DATA.investment.logo.image}
                    alt={DZIELNICA_HERO_DATA.investment.logo.alt}
                  />
                </div>
                <h4>{t(DZIELNICA_HERO_DATA.investment.stage)}</h4>
              </div>
            </motion.div>
            <div className="dzielnica-hero-tall__heading">
              <div className="dzielnica-hero-tall__heading-row">
                <motion.div
                  className={`dzielnica-hero-tall__heading-item dzielnica-hero-tall__heading-item--left ${lang}`}
                  style={{
                    transform: heading_left_transform,
                  }}
                >
                  <div>{t(DZIELNICA_HERO_DATA.heading.left)}</div>
                </motion.div>
                <motion.div
                  className={`dzielnica-hero-tall__heading-item dzielnica-hero-tall__heading-item--right ${lang}`}
                  style={{
                    transform: heading_right_transform,
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t(DZIELNICA_HERO_DATA.heading.right),
                    }}
                  ></div>
                </motion.div>
              </div>
            </div>
            <motion.div
              className="dzielnica-hero-tall__image"
              style={{ clipPath: image_cp_transform }}
            >
              <motion.div
                className="dzielnica-hero-tall__image-inner"
                style={{
                  backgroundImage: `url(${DZIELNICA_HERO_DATA.background.desktop})`,
                  transform: image_scale_transform,
                }}
              />
            </motion.div>
            <motion.div
              className="dzielnica-hero-tall__caption"
              style={{ opacity: caption_opacity }}
            >
              <div className="dzielnica-hero-tall__caption-inner">
                <p>{t(DZIELNICA_HERO_DATA.caption.location)}</p>
                <p>{t(DZIELNICA_HERO_DATA.caption.name)}</p>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default Tall
