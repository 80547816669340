import "./styles.scss"

import React, { useState, useRef } from "react"
import { motion } from "framer-motion"

import { useLocale } from "context/locale"

import AnimatedMask from "components/AnimatedMask"

const variantsDescriptionLeft = {
  hidden: { y: "25%", opacity: 0 },
  visible: { y: 0, opacity: 1 },
}

const variantsDescriptionRight = {
  hidden: { y: "-25%", opacity: 0 },
  visible: { y: 0, opacity: 1 },
}

const transition = {
  type: "tween",
  ease: [0.72, 0.15, 0.34, 0.86],
  duration: 0.8,
}

const Location = () => {
  const { t } = useLocale()
  const ref = useRef(null)

  const [animate, setAnimate] = useState(false)

  return (
    <>
      <span id="location" className="anchor anchor--small" />
      <section className="dzielnica-location" ref={ref}>
        <div className="container-dzielnica">
          <div className="dzielnica-heading dzielnica-location__heading">
            <div className="container-dzielnica">
              <motion.div
                variants={variantsDescriptionLeft}
                initial="hidden"
                animate={animate ? "visible" : "hidden"}
                transition={transition}
              >
                <div className="dzielnica-label">{t("Lokalizacja")}</div>
                <h2>
                  Wszędzie w mniej
                  <br /> niż 15 minut
                </h2>
                <p>
                  Doskonałe połączenie z resztą miasta gwarantuje bliskość
                  zajezdni autobusowej oraz nowe drogi dojazdowe prowadzące
                  bezpośrednio do osiedla.
                </p>
              </motion.div>
            </div>
          </div>

          <div className="row align-items-center justify-content-between">
            <div className="col-lg-3">
              <motion.div
                className="dzielnica-location__content"
                variants={variantsDescriptionLeft}
                initial="hidden"
                animate={animate ? "visible" : "hidden"}
                transition={transition}
              >
                <p>
                  <strong>
                    W bliskim sąsiedztwie znajdują się liczne sklepy
                  </strong>
                  , takie jak Kaufland i Lidl (w promieniu 500 metrów).
                </p>
              </motion.div>
            </div>
            <div className="col-lg-5">
              <div className="dzielnica-location__image">
                <img
                  src={
                    require("assets/images/dzielnica-trust/location-image.jpg")
                      .default
                  }
                  alt=""
                />
                <AnimatedMask
                  variant="dzielnica-green"
                  callback={() => setAnimate(true)}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <motion.div
                className="dzielnica-location__content"
                variants={variantsDescriptionRight}
                initial="hidden"
                animate={animate ? "visible" : "hidden"}
                transition={transition}
              >
                <p>
                  <strong>
                    Na Stoku oferuje także liczne tereny rekreacyjne, parki oraz
                    ścieżki spacerowe.
                  </strong>{" "}
                  Bliskość centrów kultury i handlu sprawia, że mieszkańcy mają
                  na wyciągnięcie ręki dostęp do najważniejszych usług i
                  rozrywek.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="dzielnica-location__map">
          <img
            src={
              require("assets/images/dzielnica-trust/location-map.svg").default
            }
            alt=""
          />
        </div>
      </section>
    </>
  )
}

export default Location
