import "react-image-lightbox/style.css"
import "./styles.scss"

import React, { useEffect, useRef, useState } from "react"
import {
  useMotionTemplate,
  useScroll,
  motion,
  useTransform,
} from "framer-motion"
import Slider from "react-slick"
import Lightbox from "react-image-lightbox"

import { useLocale } from "context/locale"

const gallery = [
  require("assets/images/dzielnica-trust/discover-image-1.jpg").default,
  require("assets/images/dzielnica-trust/discover-image-2.jpg").default,
  require("assets/images/dzielnica-trust/discover-image-3.jpg").default,
]

const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: false,
  puaseOnFocus: false,
  fade: false,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const getWw = () => {
  const isBrowser = typeof window !== "undefined"

  return isBrowser
    ? window.innerWidth >= 1920
      ? 1920
      : window.innerWidth
    : 1920
}

const Discover = () => {
  const { t } = useLocale()
  const ref = useRef(null)
  const galleryRef = useRef(null)
  const containerRef = useRef(null)
  const sliderRef = useRef(null)

  const [photoIndex, setPhotoIndex] = useState(0)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const { scrollYProgress: progress } = useScroll({
    target: galleryRef,
    offset: ["start", "end"],
  })

  const [windowWidth, setWindowWidth] = useState(getWw())

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(getWw())
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const timing = [0, 1]

  const slider_x = useTransform(progress, timing, [0, -100])
  const slider_x_transform = useMotionTemplate`translate(${slider_x}%, 0)`
  const container_width = useTransform(progress, timing, [0, windowWidth])
  const container_width_transform = useMotionTemplate`${container_width}px`

  return (
    <>
      <motion.section
        className="dzielnica-discover"
        style={{ "--container": container_width_transform }}
        ref={ref}
      >
        <div className="dzielnica-heading dzielnica-discover__heading">
          <div className="container-dzielnica">
            <div className="dzielnica-label">{t("Poznaj")}</div>
            <h2>Komfort w stylu trust</h2>
            <p>Części wspólne spójne z architektoniczną wizją budynku.</p>
          </div>
        </div>

        <div className="dzielnica-discover__gallery" ref={galleryRef}>
          <div className="dzielnica-discover__gallery-wrapper">
            <div className="dzielnica-discover__gallery-wrapper-inner">
              <div className="container-dzielnica">
                <div className="dzielnica-discover__gallery-slider">
                  <motion.div
                    className="dzielnica-discover__gallery-slider-inner"
                    style={{
                      transform: slider_x_transform,
                    }}
                  >
                    {gallery.map((item, index) => (
                      <div key={index}>
                        <div
                          className={`dzielnica-discover__gallery-item${
                            index === 0
                              ? " dzielnica-discover__gallery-item--shape"
                              : ""
                          }`}
                          style={{ backgroundImage: `url(${item})` }}
                        />
                      </div>
                    ))}
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-dzielnica" ref={containerRef}>
            {gallery.map((_, index) => (
              <div className="dzielnica-discover__gallery-spacer" key={index} />
            ))}
          </div>
        </div>

        <div className="dzielnica-discover__slider">
          <div className="container-dzielnica">
            <Slider {...sliderSettings} ref={sliderRef}>
              {gallery.map((item, index) => (
                <div key={index}>
                  <button
                    className="dzielnica-discover__slider-image"
                    type="button"
                    onClick={() => {
                      setIsLightboxOpen(true)
                      setPhotoIndex(index)
                    }}
                  >
                    <img src={item} alt={`Zdjęcie ${index + 1}`} />
                  </button>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </motion.section>

      {isLightboxOpen && (
        <Lightbox
          mainSrc={gallery[photoIndex]}
          nextSrc={gallery[(photoIndex + 1) % gallery.length]}
          prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length]}
          onAfterOpen={() => {
            sliderRef.current.slickPause()
          }}
          onCloseRequest={() => {
            sliderRef.current.slickPlay()
            setIsLightboxOpen(false)
          }}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )}
    </>
  )
}

export default Discover
