import "./styles.scss"

import React, { useRef } from "react"
import { motion } from "framer-motion"

const animation = {
  initial: {
    y: 48,
    opacity: 0,
  },
  whileInView: {
    y: 0,
    opacity: 1,
  },
  viewport: { once: true },
  transition: { type: "tween", duration: 0.6, ease: "easeInOut" },
}

const Interior = () => {
  const ref = useRef(null)
  const wrapperRef = useRef(null)

  return (
    <>
      <span className="anchor" id="interior"></span>
      <section className="dzielnica-interior" ref={ref}>
        <div className="dzielnica-interior__wrapper" ref={wrapperRef}>
          <div className="container-dzielnica">
            <motion.div {...animation} className="dzielnica-interior__inner">
              <div className="dzielnica-label">Sprawdź</div>
              <h2>
                Przemyślana
                <br /> koncepcja
              </h2>
              <p>
                W „Dzielnicy Trust” znajdziesz mieszkanie, które doskonale
                odpowie na Twoje oczekiwania.{" "}
                <strong>Inwestycja oferuje szeroki wybór lokali</strong> – od
                kompaktowych mieszkań jedno- i dwupokojowych o powierzchni
                od&nbsp;26&nbsp;m², po przestronne apartamenty idealne dla
                rodzin poszukujących komfortu.
              </p>
            </motion.div>
          </div>
        </div>

        <div className="dzielnica-interior__images">
          <div className="container-dzielnica">
            <div className="row align-items-end">
              <motion.div
                className="col-md-6 dzielnica-interior__images-column"
                {...animation}
              >
                <div className="dzielnica-interior__images-image">
                  <img
                    src={
                      require("assets/images/dzielnica-trust/interior-image-1.jpg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <p>Klatka schodowa</p>
              </motion.div>

              <div className="col-lg-4 col-md-5 offset-lg-2 offset-md-1 dzielnica-interior__images-column">
                <motion.div
                  className="dzielnica-interior__images-item"
                  {...animation}
                >
                  <div className="dzielnica-interior__images-image">
                    <img
                      src={
                        require("assets/images/dzielnica-trust/interior-image-2.jpg")
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="flex-end">
                    <p>
                      Reprezentacyjne lobby ze starannie dobraną muzyką i
                      delikatnym zapachem
                    </p>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Interior
